.buttonWrapper {
    --ease-up: linear(0, 0.941 11.2%, 1.203 18.3%, 1.196 22.7%, 0.962 40.7%, 1.007 62.9%, 1);
    --duration-up: 500ms;

    --ease-hover: linear(0, 0.804 19.5%, 1.02 29.6%, 1.054 38.2%, 1.001 64.3%, 1);
    --duration-hover: 300ms;

    --ease-down: cubic-bezier(0.3, 0.7, 0.3, 1);
    --duration-down: 150ms;

    --shadow-size: 6px;

    display: inline-flex;
    position: relative;
    padding-block-end: var(--shadow-size);
    min-inline-size: var(--big-button-min-inline-size, initial);

    --shadow-texture-left: 10px;
    --shadow-texture-right-offset: 53px;

    &:has(.bigButton:hover) {
        --shadow-texture-left: 12px;
        --shadow-texture-right-offset: 51px;
    }
}

.bigButton {
    --background-color-onhover: var(--background-color);
    --button-gap: 0;

    transition-property: transform;
    transition-duration: var(--duration-up);
    transition-timing-function: var(--ease-up);

    position: relative;
    overflow: hidden;
    inline-size: stretch;

    z-index: 1;

    /* Safari rounded corners bug fix: https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
    will-change: transform;
    mask-image: radial-gradient(white, black);

    &:hover {
        transition-timing-function: var(--ease-hover);
        transition-duration: var(--duration-hover);

        transform: translateY(1.5px);

        > svg:not(.shine) {
            transition-timing-function: var(--ease-hover);
            transition-duration: var(--duration-hover);

            transform: translate(-2px, 3px);
        }
    }

    &:active:not(.disabled, [aria-pressed="true"]) {
        transition-duration: var(--duration-down);
        transition-timing-function: var(--ease-down);

        transform: translateY(5px);

        .circle {
            --scale: 1;
        }
    }

    > span {
        z-index: 10;
    }
}

.circle {
    --scale: 0;

    border-radius: 999px;
    background-color: #ee6449;
    width: 110%;
    aspect-ratio: 1 / 1;
    position: absolute;
    z-index: 3;
    filter: blur(12px);

    transform: scale(var(--scale));
    transition-duration: var(--duration-down);
    transition-timing-function: var(--ease-down);
    transform-origin: center center;
}

.shadow {
    position: absolute;
    top: 50%;
    left: 0;
    inline-size: 100%;
    block-size: 50%;
    border-radius: 13px;
    z-index: 0;
    overflow: hidden;

    background: var(--productui-background-dark);

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: var(--shadow-texture-left);
        bottom: 0;
        block-size: 20px;
        inline-size: calc(100% - var(--shadow-texture-left) - var(--shadow-texture-right-offset));
        background: var(--productui-background-dark);
        box-shadow: var(--productui-background-light) 0 0 18px;

        transition-timing-function: var(--ease-hover);
        transition-duration: var(--duration-hover);
    }
}

svg.glareLeft,
svg.glareRight {
    position: absolute;
    color: var(--background-color);

    filter: drop-shadow(#ee6449 8px 8px 6px);
    inline-size: 166px;
    block-size: 65px;
}

svg.glareLeft {
    left: -125px;
    top: -13px;
    z-index: 1;
}

svg.glareRight {
    right: -64px;
    bottom: 0;
    z-index: 0;
}

svg.shine {
    inline-size: 218px;
    block-size: 84px;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    filter: blur(6px);
}
